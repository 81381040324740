<template>
  <v-row class="d-flex flex-column">
    <v-col  cols="12">
      <!-- <v-row class="d-flex justify-center">
        <v-col id="content-wrapper"
        cols="8"      
        >
          <h1 @click="$router.push('/start'),changePage('start')">Rdf</h1>
        </v-col>
      </v-row> -->
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-11 px-0">
          <h1 class="text-h4">NitrosBase RDF Storage</h1>
          <p v-if="GET_LANG == 'ru'" class="rdf-text mt-11 ">Cовременная высокопроизводительная графовая база данных. NitrosBase RDF Storage основана на семантических стандартах W3C включая RDF и SPARQL. В ней объединяются гибкость графовых моделей и мощь высокопроизводительных баз данных.</p>
          <p v-if="GET_LANG == 'en'" class="rdf-text mt-11">State of the art high-performance graph database. Based on W3C semantic standards including RDF and SPARQL. Combines the flexibility of graph models and the power of high-performance databases.</p>
          <p v-if="GET_LANG == 'ru'" class="rdf-list mt-15 ">Основные достоинства NitrosBase RDF Storage:</p>
          <p v-if="GET_LANG == 'en'" class="rdf-list mt-15">Key Benefits:</p>
          <v-list class="mt-14 bg-rdf">
            <v-list-item                   
              v-for="n in listBenefits" 
              :key="n"
              width="60%"
            >
              <div class="d-flex justify-start">                    
                <p class="list-item">{{ n }}</p>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-rdf-green">
        <v-col cols="8" class="py-6 px-0">
          <h2 v-if="GET_LANG == 'ru'" class="text-white h2-title">Быстродействие</h2>
          <h2 v-if="GET_LANG == 'en'" class="text-white h2-title">Performance</h2>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-15 px-0 d-flex">
          <v-col cols="6">
            <v-img class="img-border" src="../../assets/rdf/d1.svg"></v-img>
            <div class="diag-explane-container">
              <div class="color-container">
                <div class="color green"></div>
                <p>Virtuoso</p>
              </div>
              <div class="color-container">
                <div class="color red"></div>
                <p>NitrosBase</p>
              </div>
            </div>
            <p class="speed-text" v-if="GET_LANG == 'ru'">По результатам Ontology Summit 2014 Hackathon NitrosBase RDF обладает очень высокой производительностью. На тестах SP2bench NitrosBase превосходит ближайших конкурентов в 10-10000 раз</p>
            <p class="speed-text" v-if="GET_LANG == 'en'">Based on the results of the Ontology Summit 2014 Hackathon NitrosBase RDF has very high performance. On SP2bench benchmark NitrosBase outperforms closest competitors by 10-10000 times</p>          
          </v-col>
          <v-col cols="6">
            <v-img class="img-border" src="../../assets/rdf/d2.svg"></v-img>
            <div class="diag-explane-container">
              <div class="color-container">
                <div class="color green"></div>
                <p>Neo4j</p>
              </div>
              <div class="color-container">
                <div class="color red"></div>
                <p>NitrosBase</p>
              </div>
            </div>
            <p class="speed-text" v-if="GET_LANG == 'ru'">Проведенные независимым интегратором сравнительные испытания NitrosBase и Neo4j в рамках выполнения гранта Сколково в 2019 г. показали превосходство NitrosBase над Neo4j в среднем в несколько десятков раз.</p>
            <p class="speed-text" v-if="GET_LANG == 'en'">The independent performance comparison between NitrosBase and Neo4j <!--in the framework of the Skolkovo grant in 2019--> showed superiority of NitrosBase over Neo4j by several dozen times on average.</p>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-rdf-green">
        <v-col cols="8" class="py-6 px-0">
          <h2 v-if="GET_LANG == 'ru'" class="text-white h2-title">Открытые стандарты</h2>
          <h2 v-if="GET_LANG == 'en'" class="text-white h2-title">Open standard</h2>          
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-15 px-0">
          <p class="standart-txt" v-if="GET_LANG == 'ru'">NitrosBase RDF Storage основана на открытых стандартах W3C. Следование открытым стандартам дает следующие преимущества:</p>
          <p class="standart-txt" v-if="GET_LANG == 'en'">NitrosBase RDF is based on open W3C standards. Adherence to open standards provides the following benefits</p>
          <div class="d-flex justify-space-between">
            <v-col cols="8">
              <v-list class="d-flex flex-column justify-space-between bg-lgrey" height="100%">
                <v-list-item                   
                  v-for="n in listStandards" 
                  :key="n"                  
                  >
                    <div class="text-item">
                      {{ n }}
                    </div>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="4">
              <v-img src="../../assets/rdf/image3.svg"></v-img>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-rdf-green">
        <v-col cols="8" class="py-6 px-0">
          <h2 v-if="GET_LANG == 'ru'" class="text-white h2-title">Новые возможности обработки данных</h2>
          <h2 v-if="GET_LANG == 'en'" class="text-white h2-title">New possibilities for data processing</h2>  
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="py-15 px-0">
          <p class="standart-txt" v-if="GET_LANG == 'ru'">NitrosBase предоставляет новые возможности обработки данных, благодаря огромной производительности и поддержке современных подходов к обработке данных:</p>
          <p class="standart-txt" v-if="GET_LANG == 'en'">NitrosBase provides new ways for data processing, thanks to high performance and support for modern data processing approaches:</p>
          <v-img class="mt-12" src="../../assets/rdf/image6.svg"></v-img>
          <v-list class="d-flex justify-space-between">
            <v-list-item                   
              v-for="n in listPossibilities" 
              :key="n"  
              width="19%" 
              class="d-flex align-start flex-column"               
              > 
              <span class="item-List">{{ n }}</span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <formContact />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import formContact from "../../components/formContact/formContact.vue";
import { mapMutations, mapGetters } from "vuex";
import "./index.scss";

export default {
  components: { formContact },
  name: 'rdf',
  methods: {
    ...mapMutations(['SET_PAGE']),
    changePage(page) {
      this.$store.commit('SET_PAGE',page)
    }
  },
  computed: {
    ...mapGetters(['GET_LANG']),
    listBenefits() {
      return this.GET_LANG == 'en' ? this.listBenefEn : this.listBenef
    },
    listStandards() {
      return this.GET_LANG == 'en' ? this.listStandardsEn : this.listStandardsRu
    },
    listPossibilities() {
      return this.GET_LANG == 'en' ? this.listPossibilitiesEn : this.listPossibilitiesRu
    }
  },
  data() {
    return {
      listBenef:[
        'Быстродействие операций поиска, добавления и обновления данных;',
				'Масштабируемость, надежность;', 
				'Следование открытым стандартам;', 
				'Инновационные возможности обработки данных.'
      ],
      listBenefEn:[
        'Performance of searching, adding and modifying data;',
				'Scalability and reliability;',
				'Compliance with open standards;', 
				'Innovative data processing capabilities.'
      ],
      listStandardsEn:[
        'Availability of a large number of additional utilities for getting data from external sources, converting data to various formats, administrative utilities, etc.',
				'The ability to build systems compatible with new standards based on RDF, OWL, etc.', 
				'Availability of automatic converters from a table view of various SQL databases to graph;'
      ],
      listStandardsRu: [
        'Возможность построения систем, совместимых с новыми стандартами, основанными на RDF, OWL и т.д.',
				'Наличие большого количества дополнительных утилит для получения данных из внешних источников, преобразования данных различных форматов, выгрузки данных в различные форматы, административных утилит, и т.д.',
				'Возможность использования автоматических способов конверсии из табличного представления различных SQL баз данных в графовое'
      ],
      listPossibilitiesEn: [
        'Adding new data and modifying data without system reengineering',
        'Combining structured and unstructured data, including results of linguistic analysis',
        'Online information collecting from many diverse sources into a single semantic storage',
        'Implicit connections search and implicit information identification',
        'Developing the intelligent systems based on various AI methods including ontologies, rules, frames, semantic networks, etc.'
      ],
      listPossibilitiesRu: [
        'Добавление новых данных и модификация данных без реинжиниринга системы',
        'Возможность объединения и структурированных и неструктурированных данных, включая результаты лингвистического анализа текстов',
        'Онлайн сбор информации из множества разнородных источников в единое семантическое хранилище',
        'Возможность поиска неявных связей и выявления неявной информации',
        'Построение интеллектуальных систем, основанных на различных методах искусственного интеллекта, включая представление онтологий, правил, фреймов, семантических сетей и т.д.'
      ]
    }} 
}
</script>

<style>

</style>